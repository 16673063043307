










import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class TwitterAuthenticationPage extends Vue {
  async mounted() {
    const access_token = this.$route.query.access_token as string
    if (access_token && access_token !== 'undefined') {
      await walletStore.connectSocialAccount('apple', access_token)
    }
    this.$router.replace('/')
  }
}
